<template scoped>
  <div>
    <el-form-item
      :rules="rules.pulse"
      label="Pluse Record"
      prop="save.heartRate.pulse"
      :style="{ marginTop: '10px' }"
    >
      <el-input v-model="logic.save.heartRate.pulse" placeholder="">
        <el-switch
          slot="append"
          v-model="logic.save.heartRate.pulseValueType"
          active-text="Value"
          active-value="Value"
          inactive-text="Variable"
          inactive-value="Variable"
        ></el-switch>
      </el-input>
    </el-form-item>

    <el-form-item
      :rules="rules.year"
      label="Year"
      prop="save.heartRate.year"
      :style="{ marginTop: '10px' }"
    >
      <el-input v-model="logic.save.heartRate.year" placeholder="">
        <el-switch
          slot="append"
          v-model="logic.save.heartRate.yearValueType"
          active-text="Value"
          active-value="Value"
          inactive-text="Variable"
          inactive-value="Variable"
        ></el-switch>
      </el-input>
    </el-form-item>

    <el-form-item
      :rules="rules.month"
      label="Month"
      prop="save.heartRate.month"
      :style="{ marginTop: '10px' }"
    >
      <el-input v-model="logic.save.heartRate.month" placeholder="">
        <el-switch
          slot="append"
          v-model="logic.save.heartRate.monthValueType"
          active-text="Value"
          active-value="Value"
          inactive-text="Variable"
          inactive-value="Variable"
        ></el-switch>
      </el-input>
    </el-form-item>

    <el-form-item
      :rules="rules.day"
      label="Day"
      prop="save.heartRate.day"
      :style="{ marginTop: '10px' }"
    >
      <el-input v-model="logic.save.heartRate.day" placeholder="">
        <el-switch
          slot="append"
          v-model="logic.save.heartRate.dayValueType"
          active-text="Value"
          active-value="Value"
          inactive-text="Variable"
          inactive-value="Variable"
        ></el-switch>
      </el-input>
    </el-form-item>

    <el-form-item
      :rules="rules.hour"
      label="Hour"
      prop="save.heartRate.hour"
      :style="{ marginTop: '10px' }"
    >
      <el-input v-model="logic.save.heartRate.hour" placeholder="">
        <el-switch
          slot="append"
          v-model="logic.save.heartRate.hourValueType"
          active-text="Value"
          active-value="Value"
          inactive-text="Variable"
          inactive-value="Variable"
        ></el-switch>
      </el-input>
    </el-form-item>

    <el-form-item
      :rules="rules.minute"
      label="Minute"
      prop="save.heartRate.minute"
      :style="{ marginTop: '10px' }"
    >
      <el-input v-model="logic.save.heartRate.minute" placeholder="">
        <el-switch
          slot="append"
          v-model="logic.save.heartRate.minuteValueType"
          active-text="Value"
          active-value="Value"
          inactive-text="Variable"
          inactive-value="Variable"
        ></el-switch>
      </el-input>
    </el-form-item>

    <el-form-item
      :rules="rules.second"
      label="Second"
      prop="save.heartRate.second"
      :style="{ marginTop: '10px' }"
    >
      <el-input v-model="logic.save.heartRate.second" placeholder="">
        <el-switch
          slot="append"
          v-model="logic.save.heartRate.secondValueType"
          active-text="Value"
          active-value="Value"
          inactive-text="Variable"
          inactive-value="Variable"
        ></el-switch>
      </el-input>
    </el-form-item>
  </div>
</template>
      
<script scoped>
export default {
  props: {
    logic: Object,
  },
  methods: {
    validator_pulse: function (rule, value, callback) {
      callback();
    },
    validator_year: function (rule, value, callback) {
      callback();
    },
    validator_month: function (rule, value, callback) {
      callback();
    },
    validator_day: function (rule, value, callback) {
      callback();
    },
    validator_hour: function (rule, value, callback) {
      callback();
    },
    validator_minute: function (rule, value, callback) {
      callback();
    },
    validator_second: function (rule, value, callback) {
      callback();
    },
  },
  data: function () {
    return {
      rules: {
        pulse: [{ validator: this.validator_pulse, trigger: "blur" }],
        year: [{ validator: this.validator_year, trigger: "blur" }],
        month: [{ validator: this.validator_month, trigger: "blur" }],
        day: [{ validator: this.validator_day, trigger: "blur" }],
        hour: [{ validator: this.validator_hour, trigger: "blur" }],
        minute: [{ validator: this.validator_minute, trigger: "blur" }],
        second: [{ validator: this.validator_second, trigger: "blur" }],
      },
    };
  },
};
</script>    